import React from 'react'
import {
  getImgProps,
  LibraryPostArticleBlock as LibraryPostArticleBlockComponent,
} from '@superhi/design'

import { API } from '../../api'
import { slugToTopic } from '../utils'
import { buildPath } from '../../routing/utils'

type ComponentProps = Omit<
  React.ComponentProps<typeof LibraryPostArticleBlockComponent>,
  'link' | 'image' | 'type' | 'topics'
> & {
  __typename: 'LibraryPostArticle'
  slug: string
}

type Props = ComponentProps & API.LibraryPostArticleBlockComponent

const LibraryPostArticleBlock: React.FC<Props> = ({
  slug,
  image,
  imageSquare,
  topics,
  ...props
}) => {
  const imageProps = React.useMemo(
    () =>
      getImgProps(
        imageSquare?.url || image.url,
        {
          small: 100,
          medium: 100,
          large: 50,
          xlarge: 50,
        },
        {
          fm: 'png8',
        },
      ),
    [image, imageSquare],
  )

  const href = React.useMemo(() => buildPath('posts-view', { params: { postSlug: slug } }), [slug])

  return (
    <LibraryPostArticleBlockComponent
      {...props}
      link={{ href }}
      image={{ ...imageProps, blurHash: image.blurhash }}
      topics={
        topics?.nodes?.map((topic) => ({
          topic: slugToTopic(topic.slug),
          href: `/topics/${topic.slug}`,
        })) || []
      }
    />
  )
}

export { default as FRAGMENT } from './fragments'
export default LibraryPostArticleBlock
