import { GetStaticProps } from 'next'

import initApollo, { API } from '../../api'

import { HOME } from './queries'

export type Props = {
  blocks: API.Home['libraryHomeBlocks']
}

export const getStaticProps: GetStaticProps<Props> = async () => {
  const client = initApollo({})

  const { data } = await client.query<API.Home>({
    query: HOME,
  })

  return {
    props: {
      blocks: data.libraryHomeBlocks,
    },
  }
}
