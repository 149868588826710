import { Heading, theme } from '@superhi/design'

import { buildPath } from '../../../../routing'

import { Wrapper, WrapperProps } from './styles'

type Topic = 'design' | 'code' | 'project-management' | 'working'

const CONFIG: Record<
  Topic,
  {
    color: keyof typeof theme.colors
    hoverColor: keyof typeof theme.colors
    shape: WrapperProps['$shape']
    text: string
  }
> = {
  code: {
    color: 'PALE_BLUE_50',
    hoverColor: 'BLUE_50',
    shape: 'rectangle',
    text: 'Code',
  },
  design: {
    color: 'GREEN_30',
    hoverColor: 'GREEN_60',
    shape: 'bullet-right',
    text: 'design',
  },
  working: {
    color: 'RED_10',
    hoverColor: 'RED_60',
    shape: 'pill',
    text: 'working',
  },
  'project-management': {
    color: 'YELLOW_20',
    hoverColor: 'YELLOW_60',
    shape: 'bullet-left',
    text: 'project management',
  },
}

type Props = {
  type: Topic
}

const TopicLink: React.FC<Props> = ({ type }) => (
  <Wrapper
    $shape={CONFIG[type].shape}
    color={CONFIG[type].color}
    hoverColor={CONFIG[type].hoverColor}
    href={buildPath('topics-view', { params: { topicSlug: type } })}
  >
    <Heading level="4" color="GREY_90">
      {CONFIG[type].text}
    </Heading>
  </Wrapper>
)

export default TopicLink
