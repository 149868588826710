import React from 'react'
import { getImgProps, LibraryGuideBlock as LibraryGuideBlockComponent } from '@superhi/design'

import { API } from '../../api'
import { buildPath } from '../../routing/utils'

type ComponentProps = Omit<
  React.ComponentProps<typeof LibraryGuideBlockComponent>,
  'link' | 'image'
>

type Props = ComponentProps &
  API.LibraryGuideBlockComponent & {
    imageLazy?: boolean
  }

const LibraryGuideBlock: React.FC<Props> = ({ slug, image, imageLazy, ...props }) => {
  const imageProps = React.useMemo(
    () =>
      getImgProps(image.url, {
        small: 100,
        medium: 50,
        large: 25,
        xlarge: 25,
      }),
    [image],
  )

  const href = React.useMemo(
    () => buildPath('guides-view', { params: { guideSlug: slug } }),
    [slug],
  )

  return (
    <LibraryGuideBlockComponent
      {...props}
      link={{ href }}
      image={{ ...imageProps, lazy: imageLazy, blurHash: image.blurhash }}
    />
  )
}

export { default as FRAGMENT } from './fragments'
export default LibraryGuideBlock
