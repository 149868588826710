import { Link, theme } from '@superhi/design'
import styled from 'styled-components'

export type WrapperProps = {
  $shape: 'pill' | 'bullet-left' | 'rectangle' | 'bullet-right'
}

const SHAPE: Record<WrapperProps['$shape'], string> = {
  'bullet-right': `
    border-top-right-radius: 10em;
    border-bottom-right-radius: 10em;
  `,
  rectangle: ``,
  pill: `border-radius: 10em;`,
  'bullet-left': `
  border-top-left-radius: 10em;
  border-bottom-left-radius: 10em;
  `,
}

export const Wrapper = styled(Link)<WrapperProps>`
  position: relative;
  display: block;
  background-color: currentColor;

  ${({ $shape }) => SHAPE[$shape]}

  h4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0em 2em;
    font-size: 2vw;
  }

  &:before {
    content: '';
    display: block;
    padding-top: 50%;
  }
`
