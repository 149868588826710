import React from 'react'
import { Button, Grid, PageSection, VerticalSpacer } from '@superhi/design'

import Page from '../../components/Page'
import LibraryPostArticleBlock from '../../components/LibraryPostArticleBlock'
import LibraryPostVideoBlock from '../../components/LibraryPostVideoBlock'
import LibraryPostArticleCard from '../../components/LibraryPostArticleCard'
import LibraryPostVideoCard from '../../components/LibraryPostVideoCard'
import LibraryGuideBlock from '../../components/LibraryGuideBlock'
import LibraryCollectionBlock from '../../components/LibraryCollectionBlock'
import { buildPath } from '../../routing/utils'
import { PageConfig } from '../../routing/types'
import MarketingBlockMembership from '../../components/MembershipProductCallout'

import TopicLink from './components/TopicLink'
import { Props } from './data'
import { Footer, Topics } from './styles'
import ROUTE from './route'

const GRID_1_CELL_SPAN_1: React.ComponentProps<typeof Grid.Cell>['span'] = {
  small: 'full',
  medium: 4,
  large: 6,
}
const GRID_1_CELL_SPAN_2: React.ComponentProps<typeof Grid.Cell>['span'] = {
  small: 'full',
  medium: 4,
  large: 4,
}

const VS_1 = { small: 32, medium: 40, large: 56 }
const ROW_GAP = VS_1
const ALL_TOPICS_PATH = buildPath('topics-view', { params: { topicSlug: 'all' } })

const Home: PageConfig<Props> = ({ blocks }) => (
  <Page>
    <PageSection>
      <VerticalSpacer amount={VS_1}>
        {blocks.map((item, i): React.ReactElement => {
          let content
          const imageLazy = i > 2

          switch (item.__typename) {
            case 'LibraryHomeBlockArticle1': {
              content = (
                <Grid rowGap={ROW_GAP}>
                  <Grid.Cell span="full">
                    {item.article1.map((subItem): React.ReactElement | null => {
                      switch (subItem.__typename) {
                        case 'LibraryPostArticle': {
                          return <LibraryPostArticleBlock key={subItem.slug} {...subItem} />
                        }
                        case 'LibraryPostVideo': {
                          return <LibraryPostVideoBlock key={subItem.slug} {...subItem} />
                        }
                        default: {
                          return null
                        }
                      }
                    })}
                  </Grid.Cell>
                </Grid>
              )
              break
            }

            case 'LibraryHomeBlockArticle2': {
              content = (
                <Grid rowGap={ROW_GAP}>
                  {item.article2.map((subItem): React.ReactElement | null => {
                    switch (subItem.__typename) {
                      case 'LibraryPostArticle': {
                        return (
                          <Grid.Cell key={subItem.slug} span={GRID_1_CELL_SPAN_1}>
                            <LibraryPostArticleCard
                              {...subItem}
                              headingLevel="2"
                              imageLazy={imageLazy}
                            />
                          </Grid.Cell>
                        )
                      }
                      case 'LibraryPostVideo': {
                        return (
                          <Grid.Cell key={subItem.slug} span={GRID_1_CELL_SPAN_1}>
                            <LibraryPostVideoCard
                              {...subItem}
                              headingLevel="2"
                              imageLazy={imageLazy}
                            />
                          </Grid.Cell>
                        )
                      }
                      default: {
                        return null
                      }
                    }
                  })}
                </Grid>
              )
              break
            }

            case 'LibraryHomeBlockArticle3': {
              content = (
                <Grid rowGap={ROW_GAP}>
                  {item.article3.map((subItem): React.ReactElement | null => {
                    switch (subItem.__typename) {
                      case 'LibraryPostArticle': {
                        return (
                          <Grid.Cell key={subItem.slug} span={GRID_1_CELL_SPAN_2}>
                            <LibraryPostArticleCard
                              {...subItem}
                              headingLevel="2"
                              imageLazy={imageLazy}
                            />
                          </Grid.Cell>
                        )
                      }
                      case 'LibraryPostVideo': {
                        return (
                          <Grid.Cell key={subItem.slug} span={GRID_1_CELL_SPAN_2}>
                            <LibraryPostVideoCard
                              {...subItem}
                              headingLevel="2"
                              imageLazy={imageLazy}
                            />
                          </Grid.Cell>
                        )
                      }
                      default: {
                        return null
                      }
                    }
                  })}
                </Grid>
              )
              break
            }

            case 'LibraryHomeBlockGuide1': {
              content = (
                <Grid rowGap={ROW_GAP}>
                  <Grid.Cell span="full">
                    {item.items.map((subItem): React.ReactElement => {
                      return (
                        <LibraryGuideBlock key={subItem.slug} {...subItem} imageLazy={imageLazy} />
                      )
                    })}
                  </Grid.Cell>
                </Grid>
              )
              break
            }

            case 'LibraryHomeBlockCollection1': {
              content = (
                <>
                  {item.items.map((subItem, subItemIndex): React.ReactElement => {
                    return (
                      <LibraryCollectionBlock
                        key={subItemIndex}
                        {...subItem}
                        imageLazy={imageLazy}
                      />
                    )
                  })}
                </>
              )
              break
            }

            default: {
              break
            }
          }

          return (
            <React.Fragment key={i}>
              {/* {i === 3 && <MarketingBlockMembership noBottomPadding noTopPadding />} */}
              {i === 2 ? (
                <Grid>
                  <Grid.Cell span="full">
                    <Topics>
                      <TopicLink type="code" />
                      <TopicLink type="design" />
                      <TopicLink type="project-management" />
                      <TopicLink type="working" />
                    </Topics>
                  </Grid.Cell>
                </Grid>
              ) : null}
              {content}
            </React.Fragment>
          )
        })}
      </VerticalSpacer>
    </PageSection>

    <PageSection noTopPadding>
      <Footer>
        <Button href={ALL_TOPICS_PATH}>Show me everything</Button>
      </Footer>
    </PageSection>
  </Page>
)

Home.route = ROUTE

export { getStaticProps } from './data'
export default Home
