import { gql } from '@apollo/client'

import { FRAGMENT as VIDEO_CARD_FRAGMENT } from '../../components/LibraryPostVideoCard'
import { FRAGMENT as ARTICLE_CARD_FRAGMENT } from '../../components/LibraryPostArticleCard'
import { FRAGMENT as ARTICLE_BLOCK_FRAGMENT } from '../../components/LibraryPostArticleBlock'
import { FRAGMENT as GUIDE_BLOCK_FRAGMENT } from '../../components/LibraryGuideBlock'
import { FRAGMENT as SERIES_BLOCK_FRAGMENT } from '../../components/LibraryCollectionBlock'

export const HOME = gql`
  query Home {
    libraryHomeBlocks {
      ... on LibraryHomeBlockArticle1 {
        article1: items {
          ... on LibraryPostArticle {
            ...LibraryPostArticleBlockComponent
          }
          ... on LibraryPostVideo {
            ...LibraryPostVideoCardComponent
          }
        }
      }

      ... on LibraryHomeBlockArticle2 {
        article2: items {
          ... on LibraryPostArticle {
            ...LibraryPostArticleCardComponent
          }
          ... on LibraryPostVideo {
            ...LibraryPostVideoCardComponent
          }
        }
      }

      ... on LibraryHomeBlockArticle3 {
        article3: items {
          ... on LibraryPostArticle {
            ...LibraryPostArticleCardComponent
          }
          ... on LibraryPostVideo {
            ...LibraryPostVideoCardComponent
          }
        }
      }

      ... on LibraryHomeBlockGuide1 {
        items {
          ...LibraryGuideBlockComponent
        }
      }

      ... on LibraryHomeBlockCollection1 {
        items {
          ...LibraryCollectionBlockComponent
        }
      }
    }
  }

  ${VIDEO_CARD_FRAGMENT}
  ${ARTICLE_CARD_FRAGMENT}
  ${ARTICLE_BLOCK_FRAGMENT}
  ${GUIDE_BLOCK_FRAGMENT}
  ${SERIES_BLOCK_FRAGMENT}
`
