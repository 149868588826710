import React from 'react'

import SEO from './components/SEO'

type Props = React.ComponentProps<typeof SEO> & {}

const Page: React.FC<Props> = ({ children, ...seoProps }) => (
  <>
    <SEO {...seoProps} />

    <main>{children}</main>
  </>
)

export default Page
