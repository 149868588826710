import { gql } from '@apollo/client'

import { FRAGMENT as VIDEO_CARD_FRAGMENT } from '../LibraryPostVideoCard'
import { FRAGMENT as ARTICLE_CARD_FRAGMENT } from '../LibraryPostArticleCard'

export default gql`
  fragment LibraryCollectionBlockComponent on LibraryCollection {
    id
    slug
    title
    description
    color
    type
    posts(perPage: 6) {
      nodes {
        ... on LibraryPostArticle {
          ...LibraryPostArticleCardComponent
        }
        ... on LibraryPostVideo {
          ...LibraryPostVideoCardComponent
        }
      }
      totalCount
    }
  }

  ${VIDEO_CARD_FRAGMENT}
  ${ARTICLE_CARD_FRAGMENT}
`
