import React from 'react'
import { buildUrl as buildImgixUrl } from '@superhi/design'
import Head from 'next/head'

const IMGIX_EXCLUDE_URLS = ['https://embed-ssl.wistia.com/']
const DEFAULT_TITLE = 'SuperLibrary – SuperHi'

type Props = {
  title?: string
  description?: string
  imageUrl?: string
  imageFm?: Parameters<typeof buildImgixUrl>[1]['fm']
  noIndex?: boolean
}

const SEO: React.FC<Props> = ({ title, description, imageUrl, imageFm, noIndex = false }) => {
  const formattedImageUrl = React.useMemo(
    () =>
      imageUrl
        ? IMGIX_EXCLUDE_URLS.some((url) => imageUrl.startsWith(url))
          ? imageUrl
          : buildImgixUrl(imageUrl, {
              w: 1920,
              h: 1080,
              fit: 'crop',
              fm: imageFm,
            })
        : undefined,
    [imageFm, imageUrl],
  )

  return (
    <Head>
      <title>{title ? `${title} – ${DEFAULT_TITLE}` : DEFAULT_TITLE}</title>
      {description && <meta name="description" content={description} />}

      {title && <meta name="twitter:title" content={`${title} - SuperHi`} />}
      {description && <meta name="twitter:description" content={description} />}
      {formattedImageUrl && (
        <meta key="twitter-image" name="twitter:image" content={formattedImageUrl} />
      )}

      <meta property="og:type" content="website" />
      {title && <meta property="og:title" content={`${title} - SuperHi`} />}
      {description && <meta property="og:description" content={description} />}
      {formattedImageUrl && <meta key="og-image" property="og:image" content={formattedImageUrl} />}

      {noIndex && <meta name="robots" content="noindex" />}
    </Head>
  )
}

export default SEO
