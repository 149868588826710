import React from 'react'
import {
  getImgProps,
  LibraryPostArticleCard as LibraryPostArticleCardComponent,
} from '@superhi/design'

import { API } from '../../api'
import { slugToTopic } from '../utils'
import { buildPath } from '../../routing/utils'
// import { buildPath } from '../../routing';

type ComponentProps = Omit<
  React.ComponentProps<typeof LibraryPostArticleCardComponent>,
  'link' | 'type' | 'description' | 'image' | 'topics'
>

type Props = ComponentProps &
  API.LibraryPostArticleCardComponent & {
    __typename: 'LibraryPostArticle'
    slug: string
    noDescription?: boolean
    noType?: boolean
    imageLazy?: boolean
  }

const LibraryPostArticleCard: React.FC<Props> = ({
  slug,
  image,
  noDescription,
  description,
  noType,
  type,
  imageLazy = false,
  topics,
  ...props
}) => {
  const imageProps = React.useMemo(
    () => ({
      ...getImgProps(
        image.url,
        {
          small: 100,
          medium: 50,
          large: 33,
          xlarge: 33,
        },
        {
          fm: 'png8',
        },
      ),
      lazy: imageLazy,
    }),
    [image.url, imageLazy],
  )

  const href = React.useMemo(() => buildPath('posts-view', { params: { postSlug: slug } }), [slug])

  return (
    <LibraryPostArticleCardComponent
      {...props}
      link={{ href }}
      image={{ ...imageProps, blurHash: image.blurhash }}
      topics={
        topics?.nodes?.map((topic) => ({
          topic: slugToTopic(topic.slug),
          href: `/topics/${topic.slug}`,
        })) || []
      }
      description={noDescription ? undefined : description}
      type={noType ? '' : type}
    />
  )
}

export { default as FRAGMENT } from './fragments'
export default LibraryPostArticleCard
