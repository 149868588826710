import React from 'react'
import { LibraryPostVideoBlock as LibraryPostVideoBlockComponent } from '@superhi/design'

import { API } from '../../api'
import { slugToTopic } from '../utils'
import { buildPath } from '../../routing/utils'

type ComponentProps = Omit<
  React.ComponentProps<typeof LibraryPostVideoBlockComponent>,
  'link' | 'image' | 'type' | 'topics'
> & {
  __typename: 'LibraryPostVideo'
  slug: string
}

type Props = ComponentProps & API.LibraryPostVideoBlockComponent

const LibraryPostVideoBlock: React.FC<Props> = ({ slug, image, topics, ...props }) => {
  const imageProps = React.useMemo(() => ({ src: image.url, srcSet: '', sizes: '' }), [image.url])

  const href = React.useMemo(() => buildPath('posts-view', { params: { postSlug: slug } }), [slug])

  return (
    <LibraryPostVideoBlockComponent
      {...props}
      link={{ href }}
      image={{ ...imageProps }}
      topics={
        topics?.nodes?.map((topic) => ({
          topic: slugToTopic(topic.slug),
          href: `/topics/${topic.slug}`,
        })) || []
      }
    />
  )
}

export { default as FRAGMENT } from './fragments'
export default LibraryPostVideoBlock
