import { theme } from '@superhi/design'
import styled from 'styled-components'

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Topics = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -8px;

  @media ${theme.breakpoints.large} {
    margin: 0 -16px;
  }

  > * {
    width: 25%;
    margin: 0 86px;

    @media ${theme.breakpoints.medium} {
      margin: 0 8px;
    }
  }
`
