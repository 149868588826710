import { gql } from '@apollo/client'

export default gql`
  fragment LibraryPostArticleCardComponent on LibraryPostArticle {
    id
    slug
    type
    title
    image {
      url
      blurhash
    }
    topics {
      nodes {
        id
        slug
        title
      }
    }
    description
  }
`
