import { gql } from '@apollo/client'

export default gql`
  fragment LibraryGuideBlockComponent on LibraryGuide {
    id
    slug
    title
    image {
      url
      blurhash
    }
    description
  }
`
