import { Grid, theme } from '@superhi/design'
import styled from 'styled-components'

export const MobileButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;

  @media ${theme.breakpoints.large} {
    display: none;
  }
`

export const MobileGrid = styled(Grid)`
  margin-bottom: 32px;

  @media ${theme.breakpoints.large} {
    display: none;
  }
`

export const DesktopCell = styled(Grid.Cell)`
  display: none;

  @media ${theme.breakpoints.large} {
    display: block;
  }
`
