import React from 'react'
import { LibraryPostVideoCard as LibraryPostVideoCardComponent } from '@superhi/design'

import { API } from '../../api'
import { slugToTopic } from '../utils'
import { buildPath } from '../../routing/utils'

type ComponentProps = Omit<
  React.ComponentProps<typeof LibraryPostVideoCardComponent>,
  'link' | 'type' | 'image' | 'difficultyLevel' | 'topics'
>

type Props = API.LibraryPostVideoCardComponent &
  ComponentProps & {
    __typename: 'LibraryPostVideo'
    slug: string
    noDescription?: boolean
    noType?: boolean
    imageLazy?: boolean
  }

const LibraryPostVideoCard: React.FC<Props> = ({
  slug,
  image,
  noDescription,
  description,
  noType,
  type,
  topics,
  imageLazy = false,
  ...props
}) => {
  const imageProps = React.useMemo(
    () => ({ src: image.url, srcSet: '', sizes: '', lazy: imageLazy }),
    [image.url, imageLazy],
  )

  const href = React.useMemo(() => buildPath('posts-view', { params: { postSlug: slug } }), [slug])
  const formattedTopics = React.useMemo<
    React.ComponentProps<typeof LibraryPostVideoCardComponent>['topics']
  >(
    () =>
      topics?.nodes?.map((topic) => ({
        topic: slugToTopic(topic.slug),
        href: buildPath('topics-view', { params: { topicSlug: topic.slug } }),
      })) || [],
    [topics],
  )

  return (
    <LibraryPostVideoCardComponent
      {...props}
      link={{ href }}
      image={{ ...imageProps }}
      topics={formattedTopics}
      description={noDescription ? undefined : description}
      type={noType ? '' : type}
    />
  )
}

export { default as FRAGMENT } from './fragments'
export default LibraryPostVideoCard
