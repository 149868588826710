import TopicTag from '@superhi/design/dist/cjs/components/library/TopicTag'
import { useRouter } from 'next/router'

import { SITE_URL } from '../config'

export const slugToTopic = (slug: string) =>
  slug.replace('-', '_').toUpperCase() as React.ComponentProps<typeof TopicTag>['topic']

export const useFullPageUrl = (): string => {
  const router = useRouter()
  return `${SITE_URL}${router.asPath}`
}
