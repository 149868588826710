import {
  Body,
  Button,
  ContentfulRichText,
  Grid,
  Heading,
  Label,
  PageSection,
  TextVerticalSpacer,
  VerticalSpacer,
} from '@superhi/design'
import { CommonProps } from '@superhi/design/dist/cjs/components/types'
import React from 'react'

import { API } from '../../api'
import { buildPath } from '../../routing'
import LibraryPostArticleCard from '../LibraryPostArticleCard'
import LibraryPostVideoCard from '../LibraryPostVideoCard'

import { MobileGrid, MobileButton, DesktopCell } from './styles'

type Props = CommonProps<API.LibraryCollectionBlockComponent> & { imageLazy?: boolean }

const GRID_2_CELL_SPAN_1: React.ComponentProps<typeof Grid.Cell>['span'] = {
  small: 3,
  medium: 'full',
  large: 4,
}
const GRID_2_CELL_SPAN_2a: React.ComponentProps<typeof Grid.Cell>['span'] = {
  small: 3,
  medium: 4,
  large: 4,
}
const GRID_2_CELL_SPAN_2b: React.ComponentProps<typeof Grid.Cell>['span'] = {
  small: 3,
  medium: 4,
  large: [5, 8],
}

const RICH_TEXT_1_OVERRIDES: React.ComponentProps<typeof ContentfulRichText>['nodeOverrides'] = {
  paragraph: (_, children) => <Body level="3">{children}</Body>,
}

const MAX_POSTS = 6

const LibraryCollectionBlock: React.FC<Props> = ({
  slug,
  title,
  type,
  description,
  posts,
  imageLazy,
  ...commonProps
}) => {
  const items = posts?.nodes || []
  const hasMoreItems = Number(posts?.totalCount) > MAX_POSTS

  const button = hasMoreItems && (
    <Button
      href={buildPath('series-view', { params: { seriesSlug: slug } })}
      // version="outline"
    >
      View all {posts?.totalCount} posts
    </Button>
  )

  const info = (
    <TextVerticalSpacer level="3">
      {type && (
        <Label level="1" color="GREY_70">
          {type}
        </Label>
      )}

      <Heading level="3" as="h2" color="BLUE_50">
        {title}
      </Heading>

      {description && (
        <ContentfulRichText nodeOverrides={RICH_TEXT_1_OVERRIDES}>{description}</ContentfulRichText>
      )}
    </TextVerticalSpacer>
  )

  return (
    <PageSection
      data-component-name="LibraryCollectionBlock"
      {...commonProps}
      backgroundColor="pink"
    >
      <MobileGrid>
        <Grid.Cell span={{ small: 'full', medium: 5 }}>{info}</Grid.Cell>
      </MobileGrid>

      <Grid slider={{ small: true }}>
        <DesktopCell span={GRID_2_CELL_SPAN_1}>
          <VerticalSpacer amount={{ small: 0, medium: 0, large: 32 }}>
            {info}
            {button}
          </VerticalSpacer>
        </DesktopCell>

        {items.map((post, i): React.ReactElement => {
          switch (post.__typename) {
            case 'LibraryPostArticle': {
              return (
                <Grid.Cell key={i} span={i % 2 ? GRID_2_CELL_SPAN_2a : GRID_2_CELL_SPAN_2b}>
                  <LibraryPostArticleCard
                    {...post}
                    headingLevel="3"
                    imageLazy={imageLazy}
                    noDescription
                  />
                </Grid.Cell>
              )
            }
            case 'LibraryPostVideo': {
              return (
                <Grid.Cell key={i} span={i % 2 ? GRID_2_CELL_SPAN_2a : GRID_2_CELL_SPAN_2b}>
                  <LibraryPostVideoCard
                    {...post}
                    headingLevel="3"
                    imageLazy={imageLazy}
                    noDescription
                  />
                </Grid.Cell>
              )
            }
          }
        })}
      </Grid>

      <MobileButton>{button}</MobileButton>
    </PageSection>
  )
}

export { default as FRAGMENT } from './fragments'
export default LibraryCollectionBlock
